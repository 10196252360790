import request from '@/utils/bbsrequest'
// 公共头部、底部
export function getPublic(params) {
  return request({
    url: '/index/index/getPcCommon',
    method: 'get',
    params: params
  })
}

// 发帖
export function postThread(data) {
  return request({
    url: 'thread/thread/insertThread',
    method: 'post',
    data
  })
}

// 关注小组
export function attendGroup(data) {
  return request({
    url: 'user/user/attendGroup',
    method: 'post',
    data
  })
}
